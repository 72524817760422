// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"src/modules/tax-categories/config.ts"
);
import.meta.hot.lastModified = "1740500125935.5955";
}
// REMIX HMR END

export const config = {
  path: '/dashboard/settings/tax-categories',
  apiPath: '/api/tax-categories',
}
